<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'freight',
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
      aNum: ''
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/config/farmFreight/page',
      current: 1
    })
  },
  methods: {
    getTypeText(type, childType, text) {
      return type === '1' ? text : childType === '' ? text : ''
    },
    getColumns() {
      return [
        {
          dataIndex: 'name',
          title: '名称',
          align: 'center',
          customRender: text => {
            return <div>{text}</div>
          }
        },
        {
          dataIndex: 'areaName',
          title: '运送到',
          align: 'left',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              return (
                <div class="row-text">
                  {item.type === '1'
                    ? '中国'
                    : item.areaName && item.areaName.length > 45
                    ? item.areaName.substring(0, 45) + '...'
                    : item.areaName}
                </div>
              )
            })
          }
        },
        {
          dataIndex: 'limitAmount',
          title: '满减',
          align: 'center',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              let str = ''

              if (item.childType === '1') {
                str = `${item.limitAmount}元`
              }

              if (item.childType === '2') {
                str = `${item.limitCount}件`
              }

              return (
                <div class="row-text">{!item.detailList && item.childType && item.type !== '1' && `满${str}包邮`}</div>
              )
            })
          }
        },
        {
          dataIndex: 'firstCount',
          title: '首件（个）',
          align: 'center',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              return <div class="row-text">{this.getTypeText(item.type, item.childType, item.firstCount)}</div>
            })
          }
        },
        {
          dataIndex: 'firstPrice',
          title: '运费（元）',
          align: 'center',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              return <div class="row-text">{this.getTypeText(item.type, item.childType, item.firstPrice)}</div>
            })
          }
        },
        {
          dataIndex: 'otherCount',
          title: '增加件（个）',
          align: 'center',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              return <div class="row-text">{this.getTypeText(item.type, item.childType, item.otherCount)}</div>
            })
          }
        },
        {
          dataIndex: 'otherPrice',
          title: '增加运费（元）',
          align: 'center',
          customRender: (text, record) => {
            return record.detailList.map(item => {
              return <div class="row-text">{this.getTypeText(item.type, item.childType, item.otherPrice)}</div>
            })
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            if (records.detailList) {
              return [
                {
                  name: '修改',
                  onClick: () => this.$router.push(`/platform/freightDetail?id=${records.id}`)
                },
                {
                  name: '删除',
                  type: 'pop',
                  popTitle: (
                    <span>
                      删除运费模板后 &nbsp;
                      <a
                        onClick={() => {
                          this.$router.push(`/commodityManagement/specialty?freightTemplate=${records.id}`)
                        }}
                      >
                        {records.productNum ?? 0}
                      </a>
                      &nbsp;个商品将被下架?
                    </span>
                  ),
                  okText: '确定',
                  cancelText: '取消',
                  placement: 'topRight',
                  onClick: () =>
                    api.command.delPost.call(this, {
                      url: '/config/farmFreight/delete',
                      params: {
                        id: records.id
                      }
                    })
                }
              ]
            }
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          icon: 'plus',
          type: 'primary',
          onClick: () => this.$router.push('/platform/freightDetail')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        tableColumns={this.getColumns()}
        filterRightButtonKey={['refresh']}
        button={this.getButton()}
        records={this.records}
        showRowSelect={false}
      />
    )
  }
}
</script>

<style lang="less" scoped>
.table_box {
  background: #fff;
}

/deep/ .ant-table-thead > tr > th,
/deep/ .ant-table-tbody > tr > td {
  vertical-align: top;
  padding: 12px 0 !important;
}

/deep/ .ant-table-placeholder {
  border-top: none;
}

.row-text {
  min-height: 43px;
  margin-bottom: 10px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: content-box;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }
}
</style>
